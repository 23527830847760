import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CCardBody,
  CCardHeader,
} from "@coreui/react";
import React, { useState } from "react";
import Nestable from "react-nestable";
import MenuForm from "../components/MenuForm";
import MenuItem from "../components/MenuItem";
export default function MenuManager() {
  const [menuItems, setMenuItems] = useState([
    { id: 0, text: "Trang chủ", url: "/" },
  ]);
  const renderItem = ({ item }) => {
    return <MenuItem item={item} />;
  };
  const addMenuItem = (data)  => {
    setMenuItems(...menuItems, data)
  }
  return (
    <CCard>
      <CCardHeader>Quản lý menu</CCardHeader>
      <CCardBody>
        <Nestable
          items={menuItems}
          renderItem={renderItem}
        />
        <CAccordion>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <CIcon icon={cilPlus} /> Thêm mục
            </CAccordionHeader>
            <CAccordionBody>
              <MenuForm action={setMenuItems} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCardBody>
    </CCard>
  );
}
