import axiosClient from "../../../api/axiosClient"

const gameApi = {
    get(params){
        return axiosClient.get('/games');
    },
    detail(id){
        return axiosClient.get('/games/dt/'+id);
    },
    create(data){
        return axiosClient.post('/games/store', data);
    },
    update(id, data){
        return axiosClient.post('/games/update/'+id, data);
    },
    delete(id){
        return axiosClient.post('/games/rm/'+id);
    }
}

export default gameApi