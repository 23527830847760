import * as Yup from 'yup';

const createAgencyValidator = Yup.object({
    name: Yup.string().required('Vui lòng điền tên đại lý'),
    key: Yup.string().required('Vui lòng điền public key'),
    cert: Yup.string().required('Vui lòng điền secret key'),
    parent_id: Yup.number().typeError('Vui lòng chọn đại lý cấp trên')
                            .required('Vui lòng chọn đại lý cấp trên'),
    wallet_type: Yup.number().typeError('Vui lòng chọn loại ví')
                            .required('Vui lòng chọn loại ví'),
});

export { createAgencyValidator };