import { useQuery } from "@tanstack/react-query"
import hallApi from "../api/hallApi"

function useHallDetails(id){
    return useQuery({
        queryKey: ["hallDetail"+id],
        queryFn: () => hallApi.detail(id),
    })
}

export default useHallDetails