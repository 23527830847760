import { CButton, CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import React, { useState } from 'react'
import useHall from '../../Hall/hooks/useHall'
import { Button } from '@coreui/coreui'
import { Link } from 'react-router-dom'

function HallList() {
    const { data, isLoading } = useHall()
    const [activeKey, setActiveKey] = useState(1)
    if(isLoading)
        return 'Đang tải dữ liệu...'
    const hallList  =   []
    for(let i=0; i<data.length; i++){
        console.log(i)
        hallList[data[i]['type']] = hallList[data[i]['type']]?hallList[data[i]['type']]:[]
        hallList[data[i]['type']].push(data[i])
    }
    console.log(hallList)
    
    return (
        <CCard>
            <CCardHeader>Danh sách sảnh live</CCardHeader>
            <CCardBody>
                <CNav variant="tabs" role="tablist">
                    <CNavItem>
                        <CNavLink
                            href="#!"
                            active={activeKey === 1}
                            onClick={() => setActiveKey(1)}
                        >
                            Đá dế
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink
                            href="#!"
                            active={activeKey === 2}
                            onClick={() => setActiveKey(2)}
                        >
                            Đá gà
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink
                            href="#!"
                            active={activeKey === 3}
                            onClick={() => setActiveKey(3)}
                        >
                            Đá cá
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                        <CTable>
                                <CTableHead>
                                    <CTableRow>
                                        <CTableHeaderCell>Mã</CTableHeaderCell>
                                        <CTableHeaderCell>Tên</CTableHeaderCell>
                                        <CTableHeaderCell>Trạng thái</CTableHeaderCell>
                                        <CTableHeaderCell></CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                            <CTableBody>
                                {hallList['Cricket'].map((value, key)=>{
                                    return <CTableRow key={key}>
                                        <CTableDataCell>{value.key}</CTableDataCell>
                                        <CTableDataCell>{value.name}</CTableDataCell>
                                        <CTableDataCell>{value.live===0?'OFFLINE':'ONLINE'}</CTableDataCell>
                                        <CTableDataCell>
                                            <Link className='btn btn-sm btn-primary' to={`/fight/hall/${value.id}`}>VÀO QUẢN LÝ</Link>
                                        </CTableDataCell>
                                    </CTableRow>
                                })}
                            </CTableBody>
                        </CTable>
                    </CTabPane>
                    <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                    </CTabPane>
                    <CTabPane role="tabpanel" aria-labelledby="contact-tab" visible={activeKey === 3}>
                    </CTabPane>
                </CTabContent>
            </CCardBody>
        </CCard>
    )
}

export default HallList
