import { CButton, CCard, CCardBody, CCardHeader, CLink } from '@coreui/react'
import React from 'react'
import ModuleForm from '../components/moduleForm'
import moduleApi from '../api/moduleApi'
import CIcon from '@coreui/icons-react'
import { cilArrowLeft } from '@coreui/icons'

function CreateModule() {
   
    return (
        <CCard>
            <CCardHeader>Thêm module mới
                <CLink href='/module'><CButton color='success' size='sm' className='float-end'><CIcon icon={cilArrowLeft}/> Danh sách modules</CButton></CLink>
            </CCardHeader>
            <CCardBody>
                <ModuleForm submit={moduleApi.create} />
            </CCardBody>
        </CCard>
    )
}

export default CreateModule
