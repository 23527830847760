import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthMiddleware from '../../components/MiddleRoutes/AuthMiddleware'
import DashboardLayout from '../../components/Layout/DashboardLayout'
import ModuleList from './views/ModuleList'
import CreateModule from './views/CreateModule'
import UpdateModule from './views/UpdateModule'
import ModuleDetail from './views/ModuleDetail'

function ModuleFeature() {
  return (<Routes>
    <Route Component={AuthMiddleware}>
      <Route Component={DashboardLayout}>
        <Route path='/' Component={ModuleList} />
        <Route path='/:id' Component={ModuleDetail} />
        <Route path='/create' Component={CreateModule} />
        <Route path='/update' Component={UpdateModule} />
      </Route>
    </Route>
  </Routes>)
    
}

export default ModuleFeature
