import { cilBell, cilCommentSquare, cilCreditCard, cilEnvelopeOpen, cilFile, cilLockLocked, cilSettings, cilTask, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAvatar, CBadge, CContainer, CDropdown, CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle, CHeader, CHeaderNav, CHeaderToggler, CLink, CNavItem, CNavLink, CNavTitle, CSidebar, CSidebarBrand, CSidebarHeader, CSidebarNav } from '@coreui/react';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../features/Auth/context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faUsers,faSignOutAlt, faKey} from '@fortawesome/free-solid-svg-icons';
import { useSidebarStore } from '../../store';

export default function DashboardLayout() {
    
    const {sidebarShow, setSidebarShow} = useSidebarStore()
    const auth = useAuth()

    const handleLogout = async () => {
      try {
        await auth.logout();
      } catch (error) {
        console.error('Logout error:', error);
      }
    };

    return (
     <div>
      
      <Toaster position='bottom-right'/>
        <CSidebar
          className="border-end"
          position="fixed"
          visible={sidebarShow}
          onVisibleChange={(visible) => {
            setSidebarShow(visible)
          }}
        >
           <CSidebarBrand >
            <img 
              src="/logo.png" 
              alt="HALL68 Logo" 
              style={{ height: '50px', width: 'auto', margin: '0 10px 0 -30px' }} 
            />
            HALL68 Agency
          </CSidebarBrand>
          <CSidebarNav>
            {auth.user.group_id === 1 ? (
              <>
                <li className='nav-item'>
                  <Link to='/' className='nav-link'>
                    <FontAwesomeIcon icon={faTachometerAlt} className='me-2' />
                    Dashboard
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/agency/key' className='nav-link'>
                    <FontAwesomeIcon icon={faKey} className='me-2' />
                    API keys
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/agency' className='nav-link'>
                    <FontAwesomeIcon icon={faUsers} className='me-2' />
                    Sub-agent
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/user' className='nav-link'>
                    <FontAwesomeIcon icon={faUser} className='me-2' />
                    Player
                  </Link>
                </li>
                <li className='nav-item'>
                  <a href="#!" onClick={handleLogout} className='nav-link'>
                    <FontAwesomeIcon icon={faSignOutAlt} className='me-2' />
                    Logout
                  </a>
                </li>
              </>
            ) : ''}
          </CSidebarNav>
        </CSidebar>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
          <CHeader className="header header-sticky mb-4">
            <CContainer fluid>
              <CSidebarHeader>
                <CHeaderToggler onClick={() => setSidebarShow(!sidebarShow)} />
              </CSidebarHeader>
              <CHeaderNav className="ms-3">
                <CDropdown variant="nav-item">
                  <CDropdownToggle
                    placement="bottom-end"
                    className="py-0"
                    caret={false}
                  >
                    <CAvatar color="primary" textColor="white">AD</CAvatar>

                  </CDropdownToggle>
                  <CDropdownMenu
                    className="pt-0"
                    placement="bottom-end"
                  >
                    <CDropdownHeader className="bg-light fw-semibold py-2">
                      Account
                    </CDropdownHeader>
                    <CDropdownItem href="#">
                      <CIcon
                        icon={cilBell}
                        className="me-2"
                      />
                      Updates
                      <CBadge
                        color="info"
                        className="ms-2"
                      >
                        42
                      </CBadge>
                    </CDropdownItem>
                    
                    <CDropdownDivider />
                    <CDropdownItem onClick={()=>auth.logout()}>
                      <CIcon
                        icon={cilLockLocked}
                        className="me-2"
                      />
                      Logout
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CHeaderNav>
            </CContainer>
          </CHeader>
          <div className="body flex-grow-1 px-3">
            <CContainer lg>
              <Outlet />
            </CContainer>
          </div>
        </div>
     </div>
    );
}
