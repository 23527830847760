import { useQuery } from "@tanstack/react-query";
import gameApi from "../api/gameApi";

function useGameDetail(id){
    return useQuery({
        queryKey: ["gameDetail"+id],
        queryFn: () => gameApi.detail(id),
    });
}

export default useGameDetail;