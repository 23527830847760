import {
    CBreadcrumb,
    CBreadcrumbItem,
    CButton,
    CForm,
    CInputGroup,
    CInputGroupText,
    CFormInput  } from "@coreui/react";
  import React from "react";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import { createGameValidator } from '../validation/createGameValidator';
  import gameApi from "../api/gameApi";
  import Swal from 'sweetalert2';
  import { useNavigate } from "react-router-dom";
  
  export default function GameCreate() {
    const navigate = useNavigate();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {
        name: "",
        alias: "",
        description: ""
      },
      resolver: yupResolver(createGameValidator),
    });
  
    const submit = async (values) => {
      console.log(values);
      // call api
      try {
        let rs = await gameApi.create(values);
        console.log('create game rs');
        console.log(rs);
        if(rs.isSuccess) {
          Swal.fire({
            title: "Thông báo!",
            html: "Thêm mới thành công",
            timer: 2000,
            timerProgressBar: true
          }).then((result) => {
            console.log(result);
            /* Read more about handling dismissals below */
            if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
              // redirect to list 
              navigate('/game');
            }
          });
        }
      } catch(error) {
        console.log(error);
      }
    };
  
    return (
      <div>
      <CBreadcrumb>
        <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
        <CBreadcrumbItem href="/game">Game</CBreadcrumbItem>
        <CBreadcrumbItem active>Thêm mới</CBreadcrumbItem>
      </CBreadcrumb>
      <CForm>
              <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">Tên</CInputGroupText>
                <CFormInput
                  placeholder="Tên"
                  name="gameName"
                  {...register("name")}
                />
                <div
                  className="error invalid-feedback"
                  style={{ display: "flex" }}
                >
                  {errors.name?.message}
                </div>
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">Alias</CInputGroupText>
                <CFormInput
                  placeholder="Alias"
                  name="gameAlias"
                  {...register("alias")}
                />
                <div
                  className="error invalid-feedback"
                  style={{ display: "flex" }}
                >
                  {errors.alias?.message}
                </div>
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">Mô tả</CInputGroupText>
                <CFormInput
                  placeholder="Mô tả"
                  name="agencyDescription"
                  {...register("description")}
                />
              </CInputGroup>
              <CButton type='submit' className="px-4" onClick={handleSubmit(submit)}>Thêm</CButton>
            </CForm>
      </div>
    );
  }
  