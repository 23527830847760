import axiosClient from "../../../api/axiosClient"

const moduleApi = {
    get (params) {
        return axiosClient.get('/module', {
            params: params
        })
    },
    create (params) {
        return axiosClient.post('/module', params)
    },
    show(alias){
        return axiosClient.get('/module/'+alias)
    },
    createElement(payload){
        return axiosClient.post('/module/element', payload)
    },
    runElement({module, element}){
        return axiosClient.post(`/module/run/${module}/${element}`)
    }
}

export default moduleApi