import React from 'react';
import { CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell, CButton } from '@coreui/react';
import copy from 'copy-to-clipboard'; // Sử dụng import mặc định

export default function AgencyKey() {
  const keys = [
    { key: '21536652', secretKey: 'x55a54d52v2aaas2dq8we5552a5a5' },
    { key: '15842666', secretKey: '555ass8wg8jv5m52v26d5fv22' },
    // Thêm các khóa khác ở đây
  ];

  const handleCopy = (text) => {
    copy(text); // Sử dụng hàm mặc định để sao chép
    alert('Đã sao chép vào bộ nhớ tạm!');
  };

  return (
    <CTable>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell>Key</CTableHeaderCell>
          <CTableHeaderCell>Secret key</CTableHeaderCell>
          <CTableHeaderCell>Act</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {keys.map((item, index) => (
          <CTableRow key={index}>
            <CTableDataCell>{item.key}</CTableDataCell>
            <CTableDataCell>{item.secretKey}</CTableDataCell>
            <CTableDataCell>
              <CButton color="primary" onClick={() => handleCopy(item.key)}>
                Copy key
              </CButton>
              <CButton color="secondary" onClick={() => handleCopy(item.secretKey)} className="ms-2">
                Copy secret key
              </CButton>
            </CTableDataCell>
          </CTableRow>
        ))}
      </CTableBody>
    </CTable>
  );
}
