import {
  CBreadcrumb,
  CBreadcrumbItem,
  CButton,
  CRow,
  CForm,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CFormSelect,
  CFormTextarea
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { cilPlus } from '@coreui/icons';
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useAgency from '../hooks/useAgency';
import { createAgencyValidator } from '../validation/createAgencyValidator';
import agencyApi from "../api/agencyApi";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

export default function AgencyCreate() {
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      agency_id: "",
      key: "",
      cert: "",
      parent_id: 0,
      wallet_type: 1
    },
    resolver: yupResolver(createAgencyValidator),
  });

  const submit = async (values) => {
    console.log(values);
    // call api
    try {
      let rs = await agencyApi.create(values);
      console.log('create agency rs');
      console.log(rs);
      if(rs.isSuccess) {
        Swal.fire({
          title: "Thông báo!",
          html: "Thêm mới thành công",
          timer: 2000,
          timerProgressBar: true
        }).then((result) => {
          console.log(result);
          /* Read more about handling dismissals below */
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
            // redirect to list 
            navigate('/agency');
          }
        });
      }
    } catch(error) {
      console.log(error);
    }
  };

  return (
    <div>
    <CBreadcrumb>
      <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
      <CBreadcrumbItem href="/agency">Đại lý</CBreadcrumbItem>
      <CBreadcrumbItem active>Thêm mới</CBreadcrumbItem>
    </CBreadcrumb>
    <CForm>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Tên đại lý</CInputGroupText>
              <CFormInput
                placeholder="Tên đại lý"
                name="agencyName"
                {...register("name", {
                  onBlur: (e) => {                    
                    let str = e.target.value;
                    setValue('agency_id', str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '').replace(/đ|Đ/g, 'd'));
                  }
                })}
              />
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.name?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">ID đại lý</CInputGroupText>
              <CFormInput
                placeholder="ID đại lý"
                name="agencyId"
                {...register("agency_id")}
                readOnly
              />
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Public key</CInputGroupText>
              <CFormTextarea
                rows={3}
                {...register("key")}
              ></CFormTextarea>
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.key?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Secret key</CInputGroupText>
              <CFormInput
                placeholder="Secret key"
                name="agencyCert"
                {...register("cert")}
              />
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.cert?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Đại lý cấp trên</CInputGroupText>
              <CFormSelect {...register("parent_id")}>
                <option value="">--Chọn--</option>
                <option value="0">Không có</option>
                <option value="1">Agency 1</option>
                <option value="2">Agency 2</option>
              </CFormSelect>
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.parent_id?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Loại ví</CInputGroupText>
              <CFormSelect {...register("wallet_type")}>
                <option value="">--Chọn--</option>
                <option value="1">Seamless</option>
                <option value="2">Transfer</option>
              </CFormSelect>
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.wallet_type?.message}
              </div>
            </CInputGroup>
            <CButton type='submit' className="px-4" onClick={handleSubmit(submit)}>Thêm</CButton>
          </CForm>
    </div>
  );
}
