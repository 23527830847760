import { useQuery } from "@tanstack/react-query";
import agencyApi from "../api/agencyApi";

function useAgencyDetail(id){
    return useQuery({
        queryKey: ["agencyDetail"+id],
        queryFn: () => agencyApi.detail(id),
    });
}

export default useAgencyDetail;