import AgencyManager from "./views/AgencyManager";
import AgencyCreate from './views/AgencyCreate';
import AgencyEdit from "./views/AgencyEdit";
import AgencyI from "./views/AgencyI"
import AgencyKey from "./views/AgencyKey";

const route = [
    { path: "/", Component: AgencyI },
    { path: "/create", Component: AgencyCreate },
    { path: "/edit/:id", Component: AgencyEdit },
    { path: "/key", Component: AgencyKey }
];

export default route