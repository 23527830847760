import React from 'react';
import { CCard, CCardBody, CRow, CCol, CWidgetStatsB, CProgress } from '@coreui/react';

export default function Dashboard() {
  const data = {
    subAgents: 3,
    subPlayers: 200,
    totalRevenue: 1542655,
    monthlyRevenue: 254126,
    lastMonthRevenue: 180000,
    lastMonthSubAgents: 4,
    lastMonthSubPlayers: 190,
    lastMonthTotalRevenue: 950000,
    subAgentsGoal: 10,
    subPlayersGoal: 250,
    totalRevenueGoal: 1600000,
    monthlyRevenueGoal: 300000,
  };

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return 'N/A';
    const change = ((current - previous) / previous) * 100;
    return change.toFixed(2);
  };

  const renderChange = (change) => {
    if (change === 'N/A') {
      return change;
    }
    const changeNumber = parseFloat(change);
    const arrow = changeNumber > 0 ? '↑' : '↓';
    const color = changeNumber > 0 ? 'text-success' : 'text-danger';
    return (
      <span className={color}>
        {change}% {arrow}
      </span>
    );
  };

  const calculateProgress = (current, goal) => {
    return (current / goal) * 100;
  };

  const subAgentsChange = calculatePercentageChange(data.subAgents, data.lastMonthSubAgents);
  const subPlayersChange = calculatePercentageChange(data.subPlayers, data.lastMonthSubPlayers);
  const totalRevenueChange = calculatePercentageChange(data.totalRevenue, data.lastMonthTotalRevenue);
  const monthlyChange = calculatePercentageChange(data.monthlyRevenue, data.lastMonthRevenue);

  const widgetStyle = (color) => ({
    border: `2px solid ${color}`,
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: 'transparent',
  });

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="6" lg="3">
            <CWidgetStatsB
              style={widgetStyle('#0d6efd')} // Primary color
              value={
                <div>
                  {data.subAgents} <small><small>({renderChange(subAgentsChange)})</small></small>
                </div>
              }
              title="Number of subordinate agents"
            />
            <CProgress 
              value={calculateProgress(data.subAgents, data.subAgentsGoal)} 
              color="primary" 
              className="mt-2"
            />
            </CCol>
          <CCol sm="6" lg="3">
            <CWidgetStatsB
              style={widgetStyle('#17a2b8')} // Info color
              value={
                <div>
                  {data.subPlayers} <small><small>({renderChange(subPlayersChange)})</small></small>
                </div>
              }
              title="Number of players"
            />
            <CProgress 
              value={calculateProgress(data.subPlayers, data.subPlayersGoal)} 
              color="info" 
              className="mt-2"
            />
            </CCol>          
          <CCol sm="6" lg="3">
            <CWidgetStatsB
              style={widgetStyle('#28a745')} // Success color
              value={
                <div>
                  ${data.monthlyRevenue.toLocaleString()} <small><small>({renderChange(monthlyChange)})</small></small>
                </div>
              }
              title="Sales this month"
            />
            <CProgress 
              value={calculateProgress(data.monthlyRevenue, data.monthlyRevenueGoal)} 
              color="success" 
              className="mt-2"
            />
            </CCol>
            <CCol sm="6" lg="3">
            <CWidgetStatsB
              style={widgetStyle('#ffc107')} // Warning color
              value={
                <div>
                  ${data.totalRevenue.toLocaleString()}
                </div>
              }
              title="Total sales"
            />
            <CProgress 
              value={calculateProgress(data.totalRevenue, data.totalRevenueGoal)} 
              color="warning" 
              className="mt-2"
            />
            </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
}
