import GameManager from "./views/GameManager";
import GameCreate from './views/GameCreate';
import GameEdit from "./views/GameEdit";

const route = [
    { path: "/", Component: GameManager },
    { path: "/create", Component: GameCreate },
    { path: "/edit/:id", Component: GameEdit }
];

export default route;