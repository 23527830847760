import {
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CBreadcrumb,
    CBreadcrumbItem,
    CButton,
    CCol
  } from "@coreui/react";
  import CIcon from '@coreui/icons-react';
  import { cilPlus, cilPencil, cilDelete } from '@coreui/icons';
  import React from "react";
  import { Link } from 'react-router-dom';

  import Swal from 'sweetalert2';
  import { useQueryClient } from '@tanstack/react-query';
  import useGame from '../hooks/useGame';
  import gameApi from "../api/gameApi";
  
export default function GameManager() {
    const { data, isLoading } = useGame();
     
    const queryClient = useQueryClient();
  
    if(isLoading)
    {
        return 'Đang tải dữ liệu...';
    }
    else {
      //console.log(data);  
    }
  
    const deleteGame = (id, name) => {
      Swal.fire({
        text: `Xác nhận xoá game ${name}`,
        showDenyButton: true,
        confirmButtonText: "Xác nhận",
        denyButtonText: `Huỷ bỏ`
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let rs = await gameApi.delete(id);
          Swal.fire({text: "Xoá thành công!"});
          // invalidate to force refetch
          queryClient.invalidateQueries({ queryKey: ['agencyList'] });
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    };
  
  
    return (
      <div>
      <CBreadcrumb>
        <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
        <CBreadcrumbItem active>Game</CBreadcrumbItem>
      </CBreadcrumb>
      <div className="row justify-content-md-right">
        <CCol md="auto" sm="auto">
          <CButton href="/game/create" color="primary" size="sm" className="float-right mb-4"><CIcon icon={cilPlus} size="sm" />Thêm mới</CButton>
        </CCol>
      </div>
      <CTable responsive>
        <CTableHead>
            <CTableRow>
                <CTableHeaderCell>Id</CTableHeaderCell>
                <CTableHeaderCell>Tên</CTableHeaderCell>
                <CTableHeaderCell>Alias</CTableHeaderCell>
                <CTableHeaderCell>Mô tả</CTableHeaderCell>
                <CTableHeaderCell>Logo</CTableHeaderCell>
                <CTableHeaderCell></CTableHeaderCell>
            </CTableRow>
        </CTableHead>
        <CTableBody>
            {data.data.map((value, key)=>{
                return <CTableRow key={key}>
                    <CTableDataCell>{value.id}</CTableDataCell>
                    <CTableDataCell>{value.name}</CTableDataCell>
                    <CTableDataCell>{value.alias}</CTableDataCell>
                    <CTableDataCell>{value.description}</CTableDataCell>
                    <CTableDataCell>{value.logo}</CTableDataCell>
                    <CTableDataCell>
                        <Link className='btn btn-sm btn-warning' to={`/game/edit/${value.id}`}><CIcon icon={cilPencil} size="sm" /> Sửa</Link>
                        <CButton color="danger" size="sm" className="ml-2" onClick={() => deleteGame(value.id, value.name)}><CIcon icon={cilDelete} size="sm" /> Xoá</CButton>
                    </CTableDataCell>
                </CTableRow>
            })}
        </CTableBody>
      </CTable>
      
      </div>
    );
  }
  