import axiosClient from "../../../api/axiosClient"

const agencyApi = {
    get(params){
        return axiosClient.get('/agency', {
            params: params
        })
    },
    detail(id){
        return axiosClient.get('/agency/dt/'+id);
    },
    create(data){
        return axiosClient.post('/agency/store', data);
    },
    update(id, data){
        return axiosClient.post('/agency/update/'+id, data);
    },
    delete(id){
        return axiosClient.post('/agency/rm/'+id);
    }
}

export default agencyApi