import React from "react";
import { Route, Routes } from "react-router-dom";
import route from "./route";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import AuthMiddleware from "../../components/MiddleRoutes/AuthMiddleware";

export default function GameFeature() {
  return (
    <Routes>
      <Route Component={AuthMiddleware}>
        <Route Component={DashboardLayout}>
          {route.map((value, key) => {
            return (
              <Route
                key={key}
                path={value.path}
                Component={value.Component}
              />
            );
          })}
        </Route>
      </Route>
    </Routes>
  );
}
