import {
    CBreadcrumb,
    CBreadcrumbItem,
    CButton,
    CForm,
    CInputGroup,
    CInputGroupText,
    CFormInput  } from "@coreui/react";
  import React, { useEffect } from "react";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import { createGameValidator } from '../validation/createGameValidator';
  import gameApi from "../api/gameApi";
  import Swal from 'sweetalert2';
  import { useParams, Link } from "react-router-dom";
  import useGameDetail from "../hooks/useGameDetail";
  
  export default function GameEdit() {
    const params = useParams();
    const { data, isLoading } = useGameDetail(params.id);
    
    useEffect(()=> {
      if(data) {
        setValue("name", data.name);
        setValue("alias", data.alias);
        setValue("description", data.description);
      }
    }, [data, isLoading]);
    const {
      register,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {
        name: "",
        agency_id: "",
        key: "",
        cert: "",
        parent_id: 0,
        wallet_type: 1
      },
      resolver: yupResolver(createGameValidator),
    });
  
    const submit = async (values) => {
      console.log(values);
      // call api
      
      try {
        let rs = await gameApi.update(params.id, values);
        console.log('update game rs');
        console.log(rs);
        if(rs.isSuccess) {
          Swal.fire({
            title: "Thông báo!",
            html: "Update thành công",
            timer: 2000,
            timerProgressBar: true
          }).then((result) => {
            /*if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
              // redirect to list 
              navigate('/agency');
            }*/
          });
        }
      } catch(error) {
        console.log(error);
      }
      
    };
  
    if(isLoading)
    {
        return 'Đang tải dữ liệu...';
    }
   /*
    if(data) {
      setValue("name", data.name);
      setValue("agency_id", data.agency_id);
      setValue("key", data.key);
      setValue("cert", data.cert);
      setValue("parent_id", data.parent_id);
      setValue("wallet_type", data.wallet_type);
    }
      */
  
    return (
      <div>
      <CBreadcrumb>
        <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
        <CBreadcrumbItem href="/game">Game</CBreadcrumbItem>
        <CBreadcrumbItem active>Sửa</CBreadcrumbItem>
      </CBreadcrumb>
      <CForm>
              <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">Tên</CInputGroupText>
                <CFormInput
                  placeholder="Tên"
                  name="gameName"
                  {...register("name")}
                />
                <div
                  className="error invalid-feedback"
                  style={{ display: "flex" }}
                >
                  {errors.name?.message}
                </div>
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">Alias</CInputGroupText>
                <CFormInput
                  placeholder="Alias"
                  name="gameAlias"
                  {...register("alias")}                  
                />
                <div
                  className="error invalid-feedback"
                  style={{ display: "flex" }}
                >
                  {errors.alias?.message}
                </div>
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">Mô tả</CInputGroupText>
                <CFormInput
                  placeholder="Mô tả"
                  name="gameDescription"
                  {...register("description")}
                />
                <div
                  className="error invalid-feedback"
                  style={{ display: "flex" }}
                >
                  {errors.description?.message}
                </div>
              </CInputGroup>
              <CButton type='submit' size="sm" className="px-4" onClick={handleSubmit(submit)}>Lưu</CButton>
              <Link className="btn btn-sm btn-secondary px-4 ml-2" to={'/game'}>Huỷ</Link>
            </CForm>
      </div>
    );
  }
  