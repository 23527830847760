import Echo from "laravel-echo";

export function createSocketConnection() {
  if (!window.Echo) {
    window.Echo = new Echo({
        authEndpoint : process.env.REACT_APP_BASE_URL+'/api/broadcast/auth',
        broadcaster: 'pusher',
        key: '90dc6c9608eee3dd9501',
        cluster: 'ap1',
        forceTLS: true,
        withCredentials: true,
        withXSRFToken: true,
    })
    console.log('tét')
  }
}