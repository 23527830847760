import {
  CBreadcrumb,
  CBreadcrumbItem,
  CButton,
  CRow,
  CForm,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CFormSelect,
  CFormTextarea
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { cilPlus } from '@coreui/icons';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useAgency from '../hooks/useAgency';
import { createAgencyValidator } from '../validation/createAgencyValidator';
import agencyApi from "../api/agencyApi";
import Swal from 'sweetalert2';
import { useNavigate, useParams, Link } from "react-router-dom";
import useAgencyDetail from "../hooks/useAgencyDetail";

export default function AgencyEdit() {
  const params = useParams();
  const { data, isLoading } = useAgencyDetail(params.id);
  
  const navigate = useNavigate();
  useEffect(()=> {
    if(data) {
      setValue("name", data.name);
      setValue("agency_id", data.agency_id);
      setValue("key", data.key);
      setValue("cert", data.cert);
      setValue("parent_id", data.parent_id);
      setValue("wallet_type", data.wallet_type);
    }
  }, [data, isLoading]);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      agency_id: "",
      key: "",
      cert: "",
      parent_id: 0,
      wallet_type: 1
    },
    resolver: yupResolver(createAgencyValidator),
  });

  const submit = async (values) => {
    console.log(values);
    // call api
    
    try {
      let rs = await agencyApi.update(params.id, values);
      console.log('update agency rs');
      console.log(rs);
      if(rs.isSuccess) {
        Swal.fire({
          title: "Thông báo!",
          html: "Update thành công",
          timer: 2000,
          timerProgressBar: true
        }).then((result) => {
          /*if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
            // redirect to list 
            navigate('/agency');
          }*/
        });
      }
    } catch(error) {
      console.log(error);
    }
    
  };

  if(isLoading)
  {
      return 'Đang tải dữ liệu...';
  }
 /*
  if(data) {
    setValue("name", data.name);
    setValue("agency_id", data.agency_id);
    setValue("key", data.key);
    setValue("cert", data.cert);
    setValue("parent_id", data.parent_id);
    setValue("wallet_type", data.wallet_type);
  }
    */

  return (
    <div>
    <CBreadcrumb>
      <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
      <CBreadcrumbItem href="/agency">Đại lý</CBreadcrumbItem>
      <CBreadcrumbItem active>Sửa</CBreadcrumbItem>
    </CBreadcrumb>
    <CForm>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Tên đại lý</CInputGroupText>
              <CFormInput
                placeholder="Tên đại lý"
                name="agencyName"
                {...register("name", {
                  onBlur: (e) => {                    
                    let str = e.target.value;
                    setValue('agency_id', str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '').replace(/đ|Đ/g, 'd'));
                  }
                })}
              />
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.name?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">ID đại lý</CInputGroupText>
              <CFormInput
                placeholder="ID đại lý"
                name="agencyId"
                {...register("agency_id")}
                readOnly
              />
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Public key</CInputGroupText>
              <CFormTextarea
                rows={3}
                {...register("key")}
              ></CFormTextarea>
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.key?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Secret key</CInputGroupText>
              <CFormInput
                placeholder="Secret key"
                name="agencyCert"
                {...register("cert")}
              />
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.cert?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Đại lý cấp trên</CInputGroupText>
              <CFormSelect {...register("parent_id")}>
                <option value="">--Chọn--</option>
                <option value="0">Không có</option>
                <option value="1">Agency 1</option>
                <option value="2">Agency 2</option>
              </CFormSelect>
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.parent_id?.message}
              </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
              <CInputGroupText id="addon-wrapping">Loại ví</CInputGroupText>
              <CFormSelect {...register("wallet_type")}>
                <option value="">--Chọn--</option>
                <option value="1">Seamless</option>
                <option value="2">Transfer</option>
              </CFormSelect>
              <div
                className="error invalid-feedback"
                style={{ display: "flex" }}
              >
                {errors.wallet_type?.message}
              </div>
            </CInputGroup>
            <CButton type='submit' size="sm" className="px-4" onClick={handleSubmit(submit)}>Lưu</CButton>
            <Link className="btn btn-sm btn-secondary px-4 ml-2" to={'/agency'}>Huỷ</Link>
          </CForm>
    </div>
  );
}
