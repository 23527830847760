import React, { useState, useEffect } from 'react';
import axiosClient from '../../../api/axiosClient';
import { CButton, CCard, CCardBody, CCardHeader, CTable, CTableHead, CTableBody, CTableRow, CTableHeaderCell, CTableDataCell } from '@coreui/react';
import profileApi from '../../User/api/profileApi';

export default function AgencyI() {
  const [users, setUsers] = useState([]); // Khởi tạo với mảng rỗng
  const [userLoggedIn, setUserLoggedIn] = useState(true); // Giả sử bạn có cách để xác định người dùng đã đăng nhập

  useEffect(() => {
    async function getListuser() {
      try {
        const response = await profileApi.getlistuser();
        setUsers([]); // Đảm bảo response.data luôn là mảng
        console.log(response);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách người dùng:", error);
      }
    }

    if (userLoggedIn) {
      getListuser();
    }
  }, [userLoggedIn]);

  const formatNumber = (number) => {
    if (number == null) return 'N/A';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <CCard>
      <CCardHeader>List of subordinate agents </CCardHeader>
      <CCardBody>
      <CTable striped hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>ID</CTableHeaderCell>
                <CTableHeaderCell>Agent name</CTableHeaderCell>
                <CTableHeaderCell>Email</CTableHeaderCell>
                <CTableHeaderCell>Phone Number</CTableHeaderCell>
                <CTableHeaderCell>Date created</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {users.map((user) => (
                <CTableRow key={user.id}>
                  <CTableDataCell>{user.id}</CTableDataCell>
                  <CTableDataCell>{user.name}</CTableDataCell>
                  <CTableDataCell>{user.email}</CTableDataCell>
                  <CTableDataCell>{user.agency_id}</CTableDataCell>
                  <CTableDataCell>{formatNumber(user.balance)}</CTableDataCell>                  
                  <CTableDataCell>{user.created_at || 'N/A'}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
      </CCardBody>
    </CCard>
  );
}
