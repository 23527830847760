import { CButton,CFormSelect , CCard, CCardBody, CCardHeader, CCol, CFormInput, CInputGroup, CInputGroupText, CListGroup, CListGroupItem, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBetEvent } from '../../../hooks/socketHook'
import hallApi from '../api/hallApi'
import Config from '../components/Config'
import useHallDetails from '../hooks/useHallDetails'
import Pusher from "pusher-js"
import Swal from 'sweetalert2'
function FightIndex() {
    const params = useParams()
    const { data, isLoading } = useHallDetails(params.id)
    const [videoSource, setVideoSource] = useState('')
    const [liveStatus, setLiveStatus] = useState(false)
    const [rateWala, setRateWala] = useState(0)
    const [rateMeron, setRateMeron] = useState(0)
    const [totalRate, setTotalRate] = useState(0);
    const [betStatus, setBetStatus] = useState(0)
    const [fightId, setFightId] = useState(null)
    const [bettings, setBettings] = useState([])
    const [totalBet, setTotalBet]   =   useState(0)
    const [betWala, setBetWala] =   useState(0)
    const [betMeron, setBetMeron] =   useState(0)
    const [betDraw, setBetDraw] =   useState(0)
    const [profitWala, setProfitWala] =   useState(0)
    const [profitMeron, setProfitMeron] =   useState(0)
    const [profitDraw, setProfitDraw] =   useState(0)
    const [selectedOption, setSelectedOption] = useState(0);

    useEffect(() => {
        if (data) {
            setVideoSource(data.hall.videoSource)
            setLiveStatus(data.hall.live === 1)
            setTotalRate(data.hall.rateTotal)
            setSelectedOption(data.hall.rateTotal)
            setRateMeron(data.rateMeron)
            setRateWala(data.rateWala)
            setBetStatus(data.status)
            setFightId(data.currentFight)
            console.log(data.hall.rateTotal)
        }
    }, [data, isLoading])
    const startEvent = async () => {
        try {
            await hallApi.startEvent(params.id)
            toggleEvent()
        } catch (error) {

        }
    }
    const endEvent = async () => {
        try {
            await hallApi.endEvent(params.id)
            toggleEvent()
        } catch (error) {

        }
    }
    const startBet = async () => {
        try {
            const fight = await hallApi.startBet(params.id)
            setBetStatus(1)
            setFightId(fight.id)
        } catch (error) {

        }
    }
    const endBet = async () => {
        try {
            await hallApi.endBet(fightId)
            setBetStatus(2)
        } catch (error) {

        }
    }
    const cancelBet = async () => {
        try {
            await hallApi.cancelBet(fightId)
            setBetStatus(0)
        } catch (error) {

        }
    }
    const setWinner = async (party) => {
        try {
            let result = await hallApi.setWinner(fightId, party)
            console.log(result)
            if(result.code == 0){
                Swal.fire(result.message)
            } else {
                setBetStatus(0)
            }
            // 
        } catch (error) {

        }
    }
    const toggleEvent = () => {
        setLiveStatus(!liveStatus)
    }
    
    const handleSelectChange = async (e) => {
        const value = e.target.value;
        console.log('chi so '+ value)
        setSelectedOption(value);

        try {
            await hallApi.setRateTotal(params.id, value);
            setTotalRate(value);

        } catch (error) {
            console.error('Failed to set total rate:', error);
        }
    };

    useEffect(() => {
        const updateRateMeron = async () => {
            try {
                console.log('ratemeron' + rateMeron);
                console.log('totalRate' + totalRate);
                await setRate('meron', rateMeron);
            } catch (error) {
                console.error('Failed to set rate meron:', error);
            }
        };
    
        if (totalRate !== null) { // Chỉ gọi nếu totalRate đã có giá trị
            updateRateMeron();
        }
    }, [totalRate]);

    const setRate = async (party, value) => {
        
        try {
            await hallApi.setRate(fightId, { party, value , totalRate})
            switch (party) {
                case 'wala':
                    setRateWala(value)
                    if(value >= 0.9){
                        setRateMeron(2-value-totalRate/10)
                    }else{
                        setRateMeron(-(value + totalRate/10))
                    }
                    break;
                case 'meron':
                    console.log('ket qua' + totalRate)
                    setRateMeron(value)
                    if(value >= 0.9){
                        setRateWala(2-value - totalRate/10)
                    }else{
                        setRateWala(-(value + totalRate/10))
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {

        }
    }
    const loadCurrentBet = async (fightId) => {
        try {
            let bets = await hallApi.currentBet(fightId)
            let currentBets = []
            let sumBet = 0
            let sumWala = 0
            let sumMeron = 0
            let sumDraw = 0
            let _profitWala = 0
            let _profitMeron = 0
            let _profitDraw = 0
            bets.forEach((value, key)=>{
                let bet = {
                    bet_id : value.id,
                    payload : {
                        user : value.user.name,
                        party: value.party,
                        amount: value.amount,
                        rate: value.rate,
                        won: value.rate * value.amount
                    }
                }
                currentBets.push(bet)
                sumBet = sumBet+value.amount
                switch (value.party) {
                    case 'wala':
                        sumWala = sumWala + value.amount
                        break;
                    case 'meron':
                        sumMeron = sumMeron + value.amount
                        break;
                    case 'draw':
                        sumDraw = sumDraw + value.amount
                        break;
                    default:
                        break;
                }
            })
            setBettings(currentBets)
            setTotalBet(sumBet)
            setBetWala(sumWala)
            setBetDraw(sumDraw)
            setBetMeron(sumMeron)
        } catch (error) {
            
        }
    }
    const updateBetInfo = (bet) => {
        setBettings([...bettings, bet])
        setTotalBet(totalBet+bet.payload.amount)
        switch (bet.payload.party) {
            case 'wala':
                setBetWala(betWala + bet.payload.amount)
                break;
            case 'meron':
                setBetWala(betMeron + bet.payload.amount)
                break;
            case 'draw':
                setBetDraw(betDraw + bet.payload.amount)
                break;
            default:
                break;
        }
    }
    useBetEvent({
        fightId: fightId,
        callBack: (payload) => {
            updateBetInfo(payload)
        }
    })
    useEffect(()=>{
        setTotalBet(0)
        setBettings([])
        loadCurrentBet(fightId)
    },[fightId])
    return (
        <>
            <CRow>
                <CCol md={5}>
                    <CCard>
                        <CCardBody>
                            <iframe width="100%" height="400" src={videoSource} allowFullScreen="" allow="autoplay" title='Video'></iframe>
                            <CCol md={12}>
                                {liveStatus == 0 ?
                                    <CButton color='primary' style={{ width: '100%' }} onClick={() => startEvent()} >Open Event</CButton>
                                    : <CButton color='light' style={{ width: '100%' }} onClick={() => endEvent()}>End Event</CButton>}
                            </CCol>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol md={7}>
                    <CCard>
                        <CCardBody>
                            <CRow>
                                <CCol md={12}>
                                    Status: {betStatus == 1 ? "Open" : "Close"}
                                </CCol>
                            </CRow>
                            {
                                liveStatus == 1 ? <CRow>
                                    <CCol md={6}>
                                        {betStatus == 1 ?
                                            <CButton style={{ width: '100%' }} onClick={() => { endBet() }}>End Bet</CButton>
                                            : <CButton style={{ width: '100%' }} disabled={betStatus != 0} onClick={() => { startBet() }}>Start Bet</CButton>}
                                    </CCol>
                                    <CCol md={6}>
                                        <CButton style={{ width: '100%' }} disabled={betStatus != 1} color="dark" onClick={() => cancelBet()}>Cancel Game</CButton>
                                    </CCol>
                                </CRow> : ""
                            }

                            <CRow className='mt-4'>

                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard className='mt-4'>
                        <CCardBody>
                            <CRow>
                                <CCol md={4}>
                                    <CCard color='danger' className='mb-4'>
                                        <CCardHeader className='text-center text-white font-bold'>MERON</CCardHeader>
                                        <CListGroup flush>
                                            <CListGroupItem>PROFIT: </CListGroupItem>
                                            <CListGroupItem>RATE: {rateMeron}</CListGroupItem>
                                            <CListGroupItem>BET: {betMeron}</CListGroupItem>
                                        </CListGroup>
                                    </CCard>
                                    <CButton color='danger' disabled={betStatus != 2} style={{ width: '100%' }} onClick={() => setWinner('meron')}>WIN</CButton>
                                </CCol>
                                <CCol md={4}>
                                    <CCard color='dark' className='mb-4'>
                                        <CCardHeader className='text-center text-white font-bold'>DRAW</CCardHeader>
                                        <CListGroup flush>
                                            <CListGroupItem>PROFIT: </CListGroupItem>
                                            <CListGroupItem>RATE 1:8 </CListGroupItem>
                                            <CListGroupItem>BET: {betDraw}</CListGroupItem>
                                        </CListGroup>
                                    </CCard>
                                    <CButton color='dark' disabled={betStatus != 2} style={{ width: '100%' }} onClick={() => setWinner('draw')}>DRAW</CButton>
                                </CCol>
                                <CCol md={4}>
                                    <CCard color='primary' className='mb-4'>
                                        <CCardHeader className='text-center text-white font-bold'>WALA</CCardHeader>
                                        <CListGroup flush>
                                            <CListGroupItem>PROFIT: </CListGroupItem>
                                            <CListGroupItem>RATE: {rateWala}</CListGroupItem>
                                            <CListGroupItem>BET: {betWala}</CListGroupItem>
                                        </CListGroup>
                                    </CCard>
                                    <CButton color='primary' disabled={betStatus != 2} style={{ width: '100%' }} onClick={() => setWinner('wala')}>WIN</CButton>
                                </CCol>
                            </CRow>
                            <CRow className='mt-4'>
                                <CCol md={12}>
                                    <CFormSelect value={selectedOption} onChange={handleSelectChange}>
                                        <option value="1.00">Chỉ số tổng = 1</option>
                                        <option value="1.20" selected>Chỉ số tổng = 1.2</option>
                                    </CFormSelect>
                                </CCol>
                            </CRow>
                            <CRow className='mt-4'>
                                <CCol md={4}>
                                    <Config party='meron' rate={rateMeron} setRate={setRate} />
                                </CCol>
                                <CCol md={4}>
                                <CInputGroup className="mb-3">
                                    <CInputGroupText>Tổng cược</CInputGroupText>
                                    <CFormInput disabled={true} value={totalBet} />
                                </CInputGroup>
                                </CCol>
                                <CCol md={4}>
                                    <Config party='wala' rate={rateWala} setRate={setRate} />
                                </CCol>
                            </CRow>                           
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow className='mt-4'>
                <CCol md={12}>
                <CCard>
                <CCardHeader>Lệnh bet</CCardHeader>
                <CCardBody>
                    <CTable>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>ID</CTableHeaderCell>
                                <CTableHeaderCell>Username</CTableHeaderCell>
                                <CTableHeaderCell>Phe</CTableHeaderCell>
                                <CTableHeaderCell>Số tiền cược</CTableHeaderCell>
                                <CTableHeaderCell>Tỷ lệ</CTableHeaderCell>
                                <CTableHeaderCell>Ăn</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {
                                bettings?bettings.map((value,key)=>{
                                    console.log(value)
                                    let payload = value.payload
                                    return <CTableRow key={key}>
                                        <CTableDataCell>{value.bet_id}</CTableDataCell>
                                        <CTableDataCell>{payload.user}</CTableDataCell>
                                        <CTableDataCell>{payload.party}</CTableDataCell>
                                        <CTableDataCell>{payload.amount}</CTableDataCell>
                                        <CTableDataCell>{payload.rate}</CTableDataCell>
                                        <CTableDataCell>{payload.won}</CTableDataCell>
                                    </CTableRow>
                                }):''
                            }
                        </CTableBody>
                    </CTable>
                </CCardBody>
                </CCard>
                </CCol>
            </CRow>
        </>
    )
}

export default FightIndex
