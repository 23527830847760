import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')): null);
  const navigate = useNavigate();
  
  const login = async (data) => {
    try {
      let {user, permissions} = await authApi.login(data);
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('permissions', JSON.stringify(permissions))
      navigate("/");
    } catch (error) {
        // Swal.fire
    }
  };
  const logout = async () => {
    setUser(null);
    localStorage.setItem('user',null)
    try {
        await authApi.logout();
        navigate('/auth/login')
    } catch (error) {
        
    } 
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
