import { useQuery } from "@tanstack/react-query"
import moduleApi from "../api/moduleApi"


function useModule(params) {
    return useQuery({
        queryKey: ['moduleList'],
        queryFn: () =>moduleApi.get(params),
      })
}

export default useModule
